.auth-form-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../img/agile2.jpg');
  background-size: cover;
  background-color: color();
  background-blend-mode: soft-light;
  background-position: center;
}

.auth-form-header {
  margin-bottom: 20px;
}

.auth-form-card {
  width: 350px !important;
}

.auth-form-wrapper form {
  margin-top: 30px;
}

.form-labels {
  text-align: left;
}

.auth-form-fields,
.auth-form-buttons {
  margin-top: 15px !important;
}

.auth-form-buttons {
  width: 100%;
}

