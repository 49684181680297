.Chat {
  text-align: center;
  max-width: 1200px;
  margin: 0 0 auto auto;
}

.Chat header {
  background-color: rgb(40, 37, 53);
  /* height: 10vh; */
  /* min-height: 50px; */
  color: white;
  position: fixed;
  width: 100%;
  max-width: 1200px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  /* padding: 10px; */
  box-sizing: border-box;
}

.Chat section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* min-height: 100vh; */
  background-color: rgb(253, 253, 253);
}

main {
  margin: 50px 0px 0px 0px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

main::-webkit-scrollbar {
  width: 0.25rem;
}

main::-webkit-scrollbar-track {
  background: #1e1e24;
}

main::-webkit-scrollbar-thumb {
  background: #6649b8;
}

.form-style {
  height: 10vh;
  position: fixed;
  bottom: 0;
  background-color: rgb(245, 245, 220);
  width: 100%;
  display: flex;
  font-size: 1.5rem;
}

.form-style button {
  width: 10%;
  background-color: rgb(56, 56, 143);
  /* text-align-last: ; */
}

input {
  line-height: 1.5;
  width: 90%;
  font-size: 1.5rem;
  background: rgb(58, 58, 58);
  color: white;
  outline: none;
  border: none;
  padding: 0 10px;
}

/* button {
  background-color: #282c34;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 1.25rem;
} */

/* button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
} */

.sign-in {
  color: #282c34;
  background: white;
  max-width: 400px;
  margin: 0 auto;
}

ul, li {
  text-align: left;
  list-style: none;
}

p.textMessage {
  max-width: 500px;
  margin-bottom: 0px;
  line-height: 24px;
  padding: 10px 20px;
  border-radius: 25px;
  position: relative;
  color: white;
  text-align: center;
  font-size: large;
}

.message {
  display: flex;
  align-items: baseline;
  margin: 15px 0px;
}


.sent {
  flex-direction: row-reverse;
}

.sent .textMessage {
  color: white;
  background: #0b93f6;
  align-self: flex-end;
}
.received p {
  background: #e5e5ea;
  color: black;
}

.timeStamp {
  background: transparent !important;
  color: grey !important;
  padding: 0 10px 0 10px;
}

img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0px 10px;
}
